<template>
    <page
        id="dashboard"
        :title="$root.translate('Dashboard')"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <dashboard-wrapper
            v-if="edited_record"
            :record="edited_record"
            entity="walker"
        >
            <section class="page-inner-container">
                <div class="page-inner-container-content text-center">
                    <open-icon glyph="flag" size="lg" library="hop" />
                    <h5>{{ $root.translate("My Challenges") }}</h5>
                    <challenges
                        :walker="edited_record"
                    />
                </div>
            </section>
            <section class="page-inner-container mt-16">
                <div class="page-inner-container-content">
                    <h5 class="text-center">{{ $root.translate("Box of Treasures") }}</h5>
                    <treasures
                        :walker="edited_record"
                    />
                </div>
            </section>
            <section v-if="team" class="page-inner-container text-center mt-16">
                <div class="page-inner-container-content">
                    <h5>{{ $root.translate("My Team Stats") }}</h5>
                </div>
            </section>
            <section v-if="team" class="page-inner-container team-stats mt-10">
                <div class="page-inner-container-content">
                    <div class="columns team-stats-panel">
                        <div class="column col-4 col-sm-12">
                            <div v-if="ghgs_for_distance(team.computed_total_distance) < 1"><span class="text-large">{{ ghgs_for_distance(team.computed_total_distance) * 1000 | nibnut.number("0,0") }}</span>g</div>
                            <div v-else-if="ghgs_for_distance(team.computed_total_distance) < 1000"><span class="text-large">{{ ghgs_for_distance(team.computed_total_distance) | nibnut.number("0,0.00") }}</span>kg</div>
                            <div v-else><span class="text-large">{{ ghgs_for_distance(team.computed_total_distance) | nibnut.number("0,0") }}</span>kg</div>
                            <div class="text-small">{{ $root.translate("total GHG averted") }}!!</div>
                        </div>
                        <div class="column col-4 col-sm-12">
                            {{ team.computed_total_trips | nibnut.number("0,0") }}
                            <div class="text-small">{{ $root.translate("Total Trips") }}</div>
                        </div>
                        <div class="column col-4 col-sm-12">
                            <span v-if="standardized_distance(team.computed_total_distance) < 1000">
                                {{ standardized_distance(team.computed_total_distance) | nibnut.number("0,0") }}<span>m</span>
                            </span>
                            <span v-else>
                                {{ (standardized_distance(team.computed_total_distance) / 1000) | nibnut.number("0,0") }}<span>km</span>
                            </span>
                            <div class="text-small">{{ $root.translate("Total Distance Walked") }}</div>
                        </div>
                    </div>

                    <h5 class="my-10">{{ $root.translate("Where My Team has Walked To") }}</h5>

                    <milestones
                        :kilometers="standardized_distance(team.computed_total_distance) / 1000"
                    />
                </div>
                <img src="@/assets/img/Walkers.png" class="hide-sm" />
            </section>
        </dashboard-wrapper>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"
import { calculator_utilities } from "@/custom/mixins"

import { OpenIcon } from "@/nibnut/components"
import { DashboardWrapper, Treasures, Challenges, Milestones } from "@/custom/components"

export default {
    name: "Dashboard",
    mixins: [is_record_editor_page, calculator_utilities],
    components: {
        OpenIcon,
        DashboardWrapper,
        Milestones,
        Challenges,
        Treasures
    },
    watch: {
        walker_uuid: "reload"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.walker_uuid
        }
    },
    computed: {
        can_reload () {
            return this.walker_uuid
        },
        cleanup_before_reload () {
            return false
        },
        reload_query () {
            return { relation_ids: this.relation_ids, fields: ["fieldset::dashboard"] }
        },
        team () {
            if(!this.edited_record) return null
            return this.entity_records("team").find(team => team.id === this.edited_record.team_id)
        }
    },
    data () {
        return {
            entity: "walker",
            entityName: "Profile",
            relation_ids: ["team"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.page {
    & > .page-content#dashboard {
        & > .page-body {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;

            section:last-child {
                margin-bottom: 0;
            }
            .page-outer-container {
                margin: 0;
                max-width: none;
                .page-inner-container {
                    width: 100%;
                    max-width: none;
                    & > .page-inner-container-content {
                        padding: 0 1rem;
                        max-width: 680px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    &.walker-stats {
                        & > .page-inner-container-content {
                            text-align: center;

                            .columns {
                                justify-content: center;
                                .column {
                                    flex: 0 0 6rem;
                                    @media (max-width: $size-sm) {
                                        margin-bottom: 0.8rem;
                                    }
                                }
                            }
                            h6 {
                                font-family: $body-font-family;
                            }
                        }
                    }
                    &.savings {
                        background-color: $gray-color-light;
                        & > .page-inner-container-content {
                            position: relative;
                            padding-top: $unit-10;
                            padding-bottom: ($unit-10 * 2);
                            text-align: center;
                            font-family: "Hop";
                            font-size: 1.2rem;
                            font-weight: normal;
                            line-height: 1rem;

                            &:before {
                                content: " ";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 100px;
                                width: 140px;
                                background: $gray-color-light no-repeat bottom left url('~@/assets/img/Left_trees_desktop.png');
                                z-index: 0;
                                @media (max-width: $size-sm) {
                                    height: 80px;
                                    width: 54px;
                                    background: $gray-color-light no-repeat bottom left url('~@/assets/img/Left_trees.png');
                                }
                            }
                            &:after {
                                content: " ";
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                height: 114px;
                                width: 198px;
                                background: $gray-color-light no-repeat bottom right url('~@/assets/img/Right_trees_desktop.png');
                                z-index: 0;
                                @media (max-width: $size-sm) {
                                    height: 122px;
                                    width: 114px;
                                    background: $gray-color-light no-repeat bottom right url('~@/assets/img/Right_trees.png');
                                }
                            }
                            h5 {
                                font-size: 1.2rem;
                                z-index: 1;
                            }
                            h4 {
                                z-index: 1;
                                & > .text-large {
                                    font-size: 2.4rem;
                                }
                            }
                        }
                    }
                    &.team-stats {
                        position: relative;
                        background: $brand-orange no-repeat bottom left url('~@/assets/img/Graphic_intro_desktop.png');
                        background-size: 52%;
                        text-align: center;

                        & > .page-inner-container-content {
                            position: relative;
                            padding-top: $unit-10;
                            padding-bottom: ($unit-10 * 2);
                            text-align: center;
                            font-family: "Hop";
                            font-size: 1.2rem;
                            font-weight: normal;
                            line-height: 1rem;
                        }

                        .team-stats-panel {
                            margin-top: -3rem;
                            padding: 1rem;
                            background-color: $brand-yellow;
                            font-size: 1.2rem;
                            font-family: "Hop";
                            font-weight: normal;
                            line-height: 1rem;
                            border-radius: 1rem;

                            .text-small {
                                font-size: 0.7rem;
                                line-height: 1.1em;
                                margin-top: 0.4rem;
                            }
                        }
                        & > img {
                            position: absolute;
                            bottom: 0;
                            right: 2vw;
                            width: 25vw;
                            z-index: $zindex-1;
                        }
                    }
                }
            }
            @media (max-width: $size-sm) {
                .page-outer-container {
                    width: 100%;
                    .page-inner-container {
                        & > .page-inner-container-content {
                            width: calc(100% - 50px);
                            padding: 0;
                            max-width: none;
                            margin-left: auto;
                            margin-right: auto;
                        }
                        &.team-stats {
                            background: $brand-orange no-repeat center bottom url('~@/assets/img/Graphic_intro.png');
                        }
                    }
                }
            }
        }
    }
}
</style>
